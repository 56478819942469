import TextField from '@modules/commons/TextField';
import Select from '@modules/commons/Select';
import Typography from '@common_typography';
import Link from '@commons/theme/Link';
import Button from '@commons/theme/Button';
import { makeStyles } from '@material-ui/core/styles';
import {
    GRAY_300, GRAY_200, GRAY_100,
} from '@src_theme/colors';
import InputAdornment from '@material-ui/core/InputAdornment';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { useState } from 'react';
import VirtualKeyboard from '@root/src/modules/commons/VirtualKeyboard';
import isUseVirtualKeyboard from '@root/src/helpers/virtualKeyboard';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '100vh',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        '@media (max-width: 1024px )': {
            gridTemplateColumns: '1fr',
            justifyItems: 'center',
            alignItems: 'center',
        },
    },
    loginImage: {
        width: '100%',
        height: '100%',
        // backgroundSize: '100% 100%',
        backgroundSize: 'cover',
        // backgroundImage: 'url(/assets/img/loginecatalog.jpg)',
        backgroundImage: 'url(/assets/img/e-katalog-login.jpg)',
        backgroundRepeat: 'no-repeat',
        '@media (min-width: 1440px )': {
            backgroundPosition: '-40px -155px',
            backgroundSize: 'unset',
        },
        '@media (max-width: 1024px )': {
            display: 'none',
        },
    },
    loginWrapper: {
        background: '#F5F4F5',
        height: '100%',
        paddingTop: '40px',
        '@media (min-width: 1440px )': {
            // paddingTop: '300px',
            paddingTop: '150px',
        },
        '@media (max-width: 1024px )': {
            height: 'auto',
            width: '80%',
        },
    },
    formWrapper: {
        marginBottom: '30px',
        maxWidth: '481px',
        margin: '0 auto',
        '@media (min-width: 1440px )': {
            marginBottom: '198px',
        },
    },
    card: {
        padding: 40,
        width: 480,
        height: 480,
        borderRadius: 8,
        margin: 'auto',
        border: `1px solid ${GRAY_100}`,
        boxShadow: `0px 1px 4px ${GRAY_200}`,
        position: 'relative',
        '@media (min-width: 1024px)': {
            margin: 0,
        },
        '& .back-button': {
            position: 'absolute',
            top: 0,
            left: 0,
            color: GRAY_300,
        },
    },
    footerHelp: {
        fontSize: 16,
        textAlign: 'center',
        margin: 0,
        lineHeight: '33px',
        display: 'block',
    },
    loginTitle: {
        fontSize: '42px',
        fontWeight: 'bold',
        lineHeight: '51px',
        color: '#2E3092',
        margin: 0,
        textTransform: 'uppercase',
    },
    loginSubTitle: {
        fontSize: '18px',
        lineHeight: '24px',
        margin: 0,
        marginTop: '14px',
        marginBottom: '20px',
        '@media (min-width: 1440px )': {
            marginBottom: '42px',
        },
    },
    buttonWrapper: {
        position: 'relative',
        '& .MuiCircularProgress-root': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    },
    textInput: {
        marginBottom: '16px',
        '@media (min-width: 1440px )': {
            marginBottom: '29px',
        },
        width: '100%',
        borderRadius: 6,
        '& .MuiInputBase-root': {
            fontSize: 20,
            margin: 0,
            padding: '24px 21px',
            borderRadius: '6px',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
        },
        '& .MuiInputBase-input': {
            color: '#000',
            height: 12,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F5F4F5',
        },
        '& input': {
            '&::placeholder': {
                color: '#000000',
                fontSize: '20px',
            },
        },
    },
    selectInput: {
        marginBottom: '16px',
        '@media (min-width: 1440px )': {
            marginBottom: '29px',
        },
        width: '100%',
        borderRadius: 6,
        '& .MuiInputBase-root': {
            fontSize: 20,
            margin: 0,
            padding: '24px 21px',
            borderRadius: '6px',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
        },
        '& .MuiInputBase-input': {
            color: '#000',
            padding: 0,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F5F4F5',
        },
        '& input': {
            '&::placeholder': {
                color: '#000000',
                fontSize: '20px',
            },
        },
        '& .MuiSelect-icon': {
            right: '21px',
        },
        '& .MuiSelect-select:focus': {
            background: '#FFFFFF',
        },
    },
    forgotPasswordLink: {
        display: 'block',
        textAlign: 'right',
        color: '#000000',
        fontSize: '19px',
        marginBottom: '24px',
        '@media (min-width: 1440px )': {
            marginBottom: '42px',
        },
    },
    submitBtn: {
        '& .MuiButton-containedPrimary': {
            background: '#2E3092',
            fontSize: '21px',
            fontWeight: 'normal',
        },
    },
    loginIcon: {
        fontSize: '27px',
        color: '#2E3092',
    },
}));

export const EcatalogLogin = (props) => {
    const styles = useStyles();
    const {
        formik,
        storeData,
    } = props;
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [inputName, setInputName] = useState('default');

    return (
        <div className={styles.formWrapper}>
            <Typography variant="p" className={styles.loginTitle}>
                Masuk ke akun
            </Typography>
            <Typography variant="p" className={styles.loginSubTitle}>
                Masukkan email kamu yang terdaftar
            </Typography>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    placeholder="Email"
                    type="email"
                    name="email"
                    disableUnderline
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={!!(formik.touched.email && formik.errors.email)}
                    errorMessage={(formik.touched.email && formik.errors.email) || null}
                    className={styles.textInput}
                    endAdornment={
                        (
                            <>
                                <InputAdornment position="end">
                                    <EmailOutlinedIcon className={styles.loginIcon} />
                                </InputAdornment>
                            </>
                        )
                    }
                    onFocus={() => {
                        if (isUseVirtualKeyboard()) {
                            setShowKeyboard(true);
                            setInputName('email');
                        }
                    }}
                />
                <TextField
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={formik.values.password}
                    disableUnderline
                    onChange={formik.handleChange}
                    error={!!(formik.touched.password && formik.errors.password)}
                    errorMessage={(formik.touched.password && formik.errors.password) || null}
                    className={styles.textInput}
                    endAdornment={
                        (
                            <>
                                <InputAdornment position="end">
                                    <HttpsOutlinedIcon className={styles.loginIcon} />
                                </InputAdornment>
                            </>
                        )
                    }
                    onFocus={() => {
                        if (isUseVirtualKeyboard()) {
                            setShowKeyboard(true);
                            setInputName('password');
                        }
                    }}
                />
                <Select
                    // label="store"
                    disableUnderline
                    className={styles.selectInput}
                    InputProps={{ disableUnderline: true }}
                    options={storeData.map((vendor) => ({ value: vendor.vendor_id, label: vendor.vendor_name }))}
                    name="storeId"
                    value={formik.values.storeId}
                    onChange={formik.handleChange}
                    error={!!(formik.touched.storeId && formik.errors.storeId)}
                    errorMessage={(formik.touched.storeId && formik.errors.storeId) || null}
                />
                <Link href="/customer/account/forgotpassword">
                    <a className={styles.forgotPasswordLink}>
                        Lupa Kata Sandi?
                    </a>
                </Link>
                <Button
                    type="submit"
                    className={styles.submitBtn}
                >
                    Sign in
                </Button>
            </form>
            <VirtualKeyboard
                formik={formik}
                showKeyboard={showKeyboard}
                setShowKeyboard={setShowKeyboard}
                value={formik.values[inputName]}
                inputName={inputName}
            />
        </div>
    );
};

export default function LoginContainer({ children }) {
    const styles = useStyles();

    return (
        <div className={styles.root}>
            <div className={styles.loginWrapper}>
                {children}
                <Typography className={styles.footerHelp}>
                    Copyright © 2018 Mitra10.com. All Rights Reserved.
                </Typography>
            </div>
            <div className={styles.loginImage} />
        </div>
    );
}
