import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const caCheckCustomerB2B = gql`
query {
    caCheckCustomerB2B {
        status
        roles {
            role
        }
        status_company
    }
}
`;

export const requestOtpLoginCustom = gql`
mutation requestOtpLoginCustom ($phonenumber: String!, $otp_type: String!){
    requestOtpLoginCustom(phonenumber: $phonenumber, otp_type: $otp_type){
        info
        email_customer
        }
    }
`;

export const otpFazpassStatusList = gql`
query {
    otpFazpassStatusList {
        otpStatus {
            otp_type
            is_active
        }
  }
} 
`;

export const getXenditCustomerId = gql`
    query {
        getXenditCustomerId {
            xendit_customer_id
        }
    }
`;

export const isEmailAvailableQuery = gql`
    query isEmailAvailable($email: String!) {
        isEmailAvailable(email: $email) {
            is_email_available
        }
    }
`;

export const getCustomerToken = gql`
mutation getToken(
    $username: String!,
    $password: String!,
    $location_id: String
    $store_code: String
) {
  internalLoginSSOEcatalog(
    username: $username, 
    password: $password, 
    location_id: $location_id,
    store_code: $store_code
  ){
      token
      originalToken
      vendor_id
    }
  }
`;

// export const getCustomerToken = gql`
// mutation getToken(
//     $username: String!,
//     $password: String!,
//     $source: String,
// ) {
//   internalGenerateCustomerToken(username: $username, password: $password, source: $source){
//       token
//       originalToken
//     }
//   }
// `;

export const getCustomerTokenOtp = gql`
mutation getToken(
    $username: String!,
    $otp: String!,
    $source: String,
) {
  internalGenerateCustomerTokenOtp(username: $username, otp: $otp, source: $source){
      originalToken
      token
      message
    }
  }
`;

export const updateFcmTokenSchema = gql`
mutation updateFcmToken($firebase_device_token: String) {
  updateCustomerFcmToken(
    input: {
      firebase_device_token: $firebase_device_token      
    }
  ) {
    fcm_token
  }
}
`;
export const updateFcmAppTokenSchema = gql`
mutation updateFcmToken($firebase_device_token_app: String) {
  updateCustomerFcmToken(
    input: {
      firebase_device_token_app: $firebase_device_token_app
      
    }
  ) {
    fcm_token
  }
}
`;
