import React, { useRef, useState, useEffect } from 'react';
import Keyboard from 'react-simple-keyboard';

const VirtualKeyboard = (props) => {
    const {
        showKeyboard,
        setShowKeyboard,
        value,
        formik,
        inputName,
    } = props;
    const keyboardRef = useRef();
    const [layout, setLayout] = useState('default');

    const handleShift = () => {
        const newLayoutName = layout === 'default' ? 'shift' : 'default';
        setLayout(newLayoutName);
    };

    const onKeyPress = (button) => {
        if (button === '{shift}' || button === '{lock}') {
            handleShift();
        } else if (button === '{enter}') {
            setShowKeyboard(false);
        }
    };

    const keyBoardOnChange = (input) => {
        formik.setFieldValue(inputName, input);
    };

    useEffect(() => {
        if (keyboardRef?.current) {
            // eslint-disable-next-line no-unused-expressions
            keyboardRef?.current?.setInput(value);
        }
    }, [value, keyboardRef, showKeyboard, inputName]);

    useEffect(() => {
        const clickHanlder = (e) => {
            if (
                !(e.target.nodeName === 'INPUT')
                && (
                    !e.target.classList.contains('hg-button')
                    && !e.target.classList.contains('hg-rows')
                    && !e.target.classList.contains('hg-row')
                )
            ) {
                setShowKeyboard(false);
            }
        };
        window.addEventListener('click', clickHanlder);
        return window.removeEventListener('click', clickHanlder, true);
    }, [keyboardRef]);

    return (
        <>
            {
                showKeyboard && (
                    <div
                        style={{
                            position: 'fixed',
                            width: '100%',
                            bottom: 0,
                            right: 0,
                            zIndex: 999999,
                        }}
                    >
                        <Keyboard
                            keyboardRef={(r) => { keyboardRef.current = r; }}
                            layoutName={layout}
                            onChange={keyBoardOnChange}
                            onKeyPress={onKeyPress}
                            inputName={inputName}
                        />
                    </div>
                )
            }
        </>
    );
};

export default VirtualKeyboard;
