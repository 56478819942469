import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import * as Schema from './schema';

// eslint-disable-next-line import/prefer-default-export
export const caCheckCustomerB2B = (options) => useLazyQuery(Schema.caCheckCustomerB2B, {
    ...options,
    context: {
        request: 'internal',
    },
    fetchPolicy: 'no-cache',
});

export const requestOtpLoginCustom = (variables) => useMutation(Schema.requestOtpLoginCustom, {
    variables,
    context: {
        request: 'internal',
    },
});

export const otpFazpassStatusList = () => useQuery(Schema.otpFazpassStatusList, {
    context: {
        request: 'internal',
    },
});

export const getToken = () => useMutation(Schema.getCustomerToken, {
    context: {
        request: 'internal',
    },
});

export const getTokenOtp = () => useMutation(Schema.getCustomerTokenOtp, {
    context: {
        request: 'internal',
    },
});

export const updateFcmToken = () => useMutation(Schema.updateFcmTokenSchema, {
    context: {
        request: 'internal',
    },
});

export const updateFcmAppToken = () => useMutation(Schema.updateFcmAppTokenSchema, {
    context: {
        request: 'internal',
    },
});
