export const list = {
    androidApps: 'wrapper-pwa-mitra10-android',
    iosApps: 'wrapper-pwa-mitra10-ios',
};

export const IOSDevices = [
    list.iosApps,
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
];

export const isIosDevices = () => {
    if (typeof window === 'undefined') return '';
    const ua = window.navigator.userAgent;
    return IOSDevices.includes(ua)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
};

const isMobileApp = () => {
    if (typeof window === 'undefined') return '';

    const ua = window.navigator.userAgent;
    return (ua === list.androidApps || ua === list.iosApps) ? 'app' : 'web';
};

export default isMobileApp;
