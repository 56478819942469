/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import Layout from '@layout';
import { setLogin, getLastPathWithoutLogin } from '@helper_auth';
import { setCartId, getCartId } from '@helper_cartid';
import { useQuery, useApolloClient } from '@apollo/client';
import { expiredToken, custDataNameCookie } from '@config';
import Router from 'next/router';
import Cookies from 'js-cookie';
import {
    // getToken,
    // getTokenOtp,
    removeToken as deleteToken,
    getCustomerCartId,
    mergeCart as mutationMergeCart,
} from '@core_modules/login/services/graphql';
import { getCustomer } from '@core_modules/login/services/graphql/schema';
import {
    caCheckCustomerB2B,
    getToken,
    getTokenOtp,
    updateFcmToken,
    updateFcmAppToken,
} from '@modules/login/services/graphql';
import { getStoreSwitcher } from '@modules/theme/services/graphql';
import { setVendor } from '@helper_cookies';
import { getXenditCustomerId } from '@modules/login/services/graphql/schema';
import React, { useState, useEffect } from 'react';
import isMobileApp, { isIosDevices } from '@root/src/helpers/userAgent';

import { useTranslation } from '@i18n';
import firebase from '@root/core/lib/firebase';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { setLoginEcatalog } from '@root/src/helpers/authEcatalog';
import LoginContainer, {
    EcatalogLogin,
} from './components';

const base64Regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

function useLogin(storeConfig, query, lastPathNoAuth) {
    const { t } = useTranslation(['login']);
    const client = useApolloClient();
    const [isRevokeToken, setRevokeToken] = React.useState(false);
    // const [disabled, setDisabled] = React.useState(false);
    // const [loading, setLoading] = React.useState(false);
    const [cusIsLogin, setIsLogin] = React.useState(0);

    let cartId = '';
    let redirectLastPath = lastPathNoAuth;
    const expired = storeConfig.oauth_access_token_lifetime_customer
        ? new Date(Date.now() + parseInt(storeConfig.oauth_access_token_lifetime_customer, 10) * 3600000)
        : expiredToken;

    if (typeof window !== 'undefined') {
        cartId = getCartId();
        if (lastPathNoAuth === '' || !lastPathNoAuth) {
            redirectLastPath = getLastPathWithoutLogin();
        }
    }

    const [fcmToken, setFcmToken] = useState('');
    const [deleteTokenGql] = deleteToken();
    const [getCustomerToken] = getToken();
    const [getCustomerTokenOtp] = getTokenOtp();
    const [getCart, cartData] = getCustomerCartId();
    const [mergeCart, { called }] = mutationMergeCart();
    const [updateFirebaseToken] = updateFcmToken();
    const [updateFirebaseTokenApp] = updateFcmAppToken();
    const custData = useQuery(getCustomer, {
        context: {
            request: 'internal',
        },
        skip: !cusIsLogin,
    });
    const [checkCustomerB2B] = caCheckCustomerB2B({
        skip: !cusIsLogin,
        onCompleted: (res) => {
            Cookies.set('customerB2B', {
                ...res.caCheckCustomerB2B,
            }, {
                sameSite: 'none', secure: true,
            });
            getCart();
        },
    });

    // handle revoke token
    React.useEffect(() => {
        if (!isRevokeToken && typeof window !== 'undefined') {
            setRevokeToken(true);
            deleteTokenGql();
        }
    }, [isRevokeToken]);

    const emailPasswordLogin = async (
        email,
        password,
        location_id = '',
        store_code = '',
        isIframe = false,
    ) => {
        // setDisabled(true);
        // setLoading(true);
        window.backdropLoader(true);
        getCustomerToken({
            variables: {
                username: email,
                password,
                location_id: isIframe ? '' : location_id,
                store_code: isIframe ? store_code : '',
            },
        })
            .then(async (res) => {
                const { originalToken, vendor_id } = res.data.internalLoginSSOEcatalog;
                if (originalToken) {
                    window.backdropLoader(false);
                    setVendor(Number(vendor_id));
                    setLoginEcatalog(1, 1);
                    Router.push('/');
                } else {
                    window.backdropLoader(false);
                    throw new Error('login gagal, email dan password tidak sesuai');
                }
            })
            .catch((e) => {
                // setDisabled(false);
                // setLoading(false);
                window.backdropLoader(false);
                window.toastMessage({
                    open: true,
                    variant: 'error',
                    text: e.message.split(':')[0] || t('login:failed'),
                });
            });
    };

    const otpLogin = async (email, otp) => {
        // setDisabled(true);
        // setLoading(true);
        window.backdropLoader(true);
        getCustomerTokenOtp({ variables: { username: email, otp, source: isMobileApp() } })
            .then(async (res) => {
                const resGetXenditCustomerId = await client.query({
                    query: getXenditCustomerId,
                    context: {
                        request: 'internal',
                    },
                });
                localStorage.setItem('xdt_customer_id', resGetXenditCustomerId.data.getXenditCustomerId.xendit_customer_id);
                const { token } = res.data.internalGenerateCustomerTokenOtp;
                if (token) {
                    if (isMobileApp() === 'app') {
                        updateFirebaseTokenApp({
                            variables: {
                                firebase_device_token_app: window?.reactNativeApp || '',
                            },
                        });
                    } else {
                        updateFirebaseToken({
                            variables: {
                                firebase_device_token: fcmToken,
                            },
                        });
                    }
                    setLogin(1, expired);
                    setIsLogin(1);
                    checkCustomerB2B();
                }
            })
            .catch((e) => {
                // setDisabled(false);
                // setLoading(false);
                window.backdropLoader(false);
                window.toastMessage({
                    open: true,
                    variant: 'error',
                    text: e.message.split(':')[0] || t('login:failed'),
                });
            });
    };

    if (cartData.data && custData.data) {
        Cookies.set(custDataNameCookie, {
            email: custData.data.customer.email,
            firstname: custData.data.customer.firstname,
        }, {
            sameSite: 'none', secure: true,
        });
        const custCartId = cartData.data.customerCart.id;
        if (cartId === '' || !cartId) {
            setCartId(custCartId, expired);
            // setDisabled(false);
            window.backdropLoader(false);
            window.toastMessage({ open: true, variant: 'success', text: t('login:success') });
            if (query && query.redirect) {
                setTimeout(() => {
                    Router.push(query.redirect);
                }, 1500);
            } else if (redirectLastPath && redirectLastPath !== '') {
                Router.push(redirectLastPath);
            } else {
                Router.push('/customer/account');
            }
        } else if (!called && cartId !== custCartId) {
            mergeCart({
                variables: {
                    sourceCartId: cartId,
                    destionationCartId: custCartId,
                },
            })
                .then(async (res) => {
                    await setCartId(res.data.mergeCarts.id, expired);
                    // await setDisabled(false);
                    window.backdropLoader(false);
                    window.toastMessage({ open: true, variant: 'success', text: t('login:success') });
                    setTimeout(() => {
                        if (query && query.redirect) {
                            Router.push(query.redirect);
                        } else if (redirectLastPath && redirectLastPath !== '') {
                            Router.push(redirectLastPath);
                        } else {
                            Router.push('/customer/account');
                        }
                    }, 1500);
                })
                .catch(() => {});
        } else if (query && query.redirect) {
            Router.push(query.redirect);
        } else if (redirectLastPath && redirectLastPath !== '') {
            Router.push(redirectLastPath);
        }
    }

    React.useState(() => {
        Cookies.set('customerB2B', { status: false }, { sameSite: 'none', secure: true });
    }, []);

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            if (isMobileApp() !== 'app') {
                const isIos = isIosDevices();
                if (!isIos) {
                    const getTokenFcm = () => {
                        const messaging = firebase.messaging();
                        messaging
                            .getToken()
                            .then((currentToken) => {
                                if (currentToken) {
                                    setFcmToken(currentToken);
                                }
                            })
                            .catch(() => {});
                    };
                    getTokenFcm();
                }
            }
        }
    }, []);

    return { emailPasswordLogin, otpLogin };
}

const Login = (props) => {
    const { t } = useTranslation(['login', 'otp']);
    const config = {
        title: t('login:pageTitle'),
        header: 'relative', // available values: "absolute", "relative", false (default)
        headerTitle: t('login:pageTitle'),
        headerBackIcon: 'close',
        bottomNav: true,
        fullWidth: true,
        headerDesktop: false,
        footer: false,
    };

    const { storeConfig, query, lastPathNoAuth } = props;
    const { data } = getStoreSwitcher();
    const {
        emailPasswordLogin,
    } = useLogin(storeConfig, query, lastPathNoAuth);

    const LoginPhoneEmailSchema = Yup.object().shape({
        email: Yup.string().email(t('validate:email:wrong')).required(t('validate:email:required')),
        password: Yup.string().required(t('validate:password:required')),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            storeId: data?.storeSwitcher?.default_vendor_id || 18,
        },
        validationSchema: LoginPhoneEmailSchema,
        onSubmit: (values) => {
            const location_id = String(values.storeId);
            emailPasswordLogin(values.email, values.password, location_id);
        },
    });

    useEffect(() => {
        if (query?.token) {
            const { token } = query;
            if (base64Regex.test(token)) {
                const decodeToken = JSON.parse(atob(token));
                const { username = '', password = '', storeId = '' } = decodeToken;
                emailPasswordLogin(
                    username,
                    password,
                    '',
                    String(storeId),
                    true,
                );
            } else {
                window.backdropLoader(false);
                window.toastMessage({
                    open: true,
                    variant: 'error',
                    text: 'login gagal, token tidak sesuai',
                });
            }
        }
    }, [query]);

    return (
        <Layout
            {...props}
            pageConfig={config}
            isUseSideMenu={false}
            noPaddingPage
        >
            <LoginContainer>
                <EcatalogLogin
                    formik={formik}
                    storeData={data?.storeSwitcher?.options || []}
                />
            </LoginContainer>
        </Layout>
    );
};

export default Login;
