/* eslint-disable import/prefer-default-export */
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import schema, { getCmsBlocks as getCmsBlocksSchema } from './schema';

export const getCategories = () => useQuery(schema.categories);
export const getCategoryByName = (name) => useLazyQuery(schema.getCategoryByName(name));
export const getProduct = (key) => useLazyQuery(schema.getProduct(key));
export const getVesMenu = (options) => useQuery(schema.vesMenu, options);

export const getCustomer = () => useQuery(schema.getCustomer, {
    context: {
        request: 'internal',
        skip: typeof window === 'undefined',
    },
    fetchPolicy: 'cache-and-network',
});

export const removeToken = () => useMutation(schema.removeToken, {
    context: {
        request: 'internal',
    },
});

export const getCmsBlocks = (variables) => useQuery(getCmsBlocksSchema, { variables });

export const getStoreSwitcher = () => useQuery(schema.getStoreSwitcher, {});

export const getCountProductCompare = (variables) => useQuery(schema.countCompare, {
    variables,
    context: {
        request: 'internal',
    },
    fetchPolicy: 'cache-and-network',
});

export const getPopularTerms = (options) => useQuery(schema.popularTerms, options);
export const getPopularCategory = (options) => useQuery(schema.popularCategory, options);
export const setSearchTerm = (variable) => useMutation(schema.setSearchTerm, {
    variable,
    context: {
        request: 'internal',
    },
});

export const caCheckCustomerB2B = () => useQuery(schema.caCheckCustomerB2B, {
    context: {
        request: 'internal',
        skip: typeof window === 'undefined',
    },
    fetchPolicy: 'cache-and-network',
});

export default {
    getCmsBlocks,
    getCategories,
    getCustomer,
    removeToken,
    getVesMenu,
    getProduct,
    getCategoryByName,
    getStoreSwitcher,
    getCountProductCompare,
    getPopularTerms,
    getPopularCategory,
    setSearchTerm,
    caCheckCustomerB2B,
};
